@import "../index";
.after_sale_manage_detail {
  .order_table_header {
    color: #333333;
    text-align: center;
    background: #fafafa !important;
    padding: 10px 0;
    background: rgba(56, 127, 253, 0.05);
  }

  .order_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .order_detail {
      border-radius: 0px 4px 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .order_detail_status {
        height: 22px;
        font-size: 16px;
        
        font-weight: 500;
        color: #262626;
        line-height: 22px;
        margin-bottom: 8px;
      }
      .order_detail_tips {
        height: 22px;
        font-size: 14px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        margin: 0 !important;
      }
    }
    .order_deal {
      margin-right: 20px;
    }
  }

  .track {
    .track_tit {
      height: 54px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 14px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 24px;
      h4 {
        width: 64px;
        height: 24px;
        font-size: 16px;
        font-weight: 600;
        color: #262626;
        line-height: 24px;
      }
    }
    .track_con {
      padding-left: 14px;
      .ant-tabs-content-holder {
        p {
          text-align: left;
          margin: 10px 0;
        }
        .track_con {
          margin: -14px 0 0 80px;
        }
      }
    }
  }
  .order_infor {
    min-width: 900px;
    // margin-bottom: 20px;
    .order_infor_tit {
      // height: 54px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 14px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 18px;
      h4 {
        width: 64px;
        height: 24px;
        font-size: 16px;
        
        font-weight: 600;
        color: #262626;
        line-height: 24px;
      }
    }
    .order_infor_con_wrap {
      display: flex;
      justify-content: space-between;
      .order_infor_con {
        height: 124px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 12px;
        padding:0 14px;
        p {
          height: 22px;
          font-size: 14px;
          color: #262626;
          line-height: 22px;
          span {
            color: #787878;
          }
        }
      }
    }
    .order_infor_con_desc {
      // margin-top: 44px;
      margin-bottom: 12px;
      padding:0 14px;
      height: 22px;
      font-size: 14px;
      color: #262626;
      line-height: 22px;
      span {
        color: #787878;
      }
    }
    .order_infor_con_img {
      padding-left: 82px;
      overflow: hidden;
      .img {
        width: 90px;
        float: left;
        margin-right: 20px;
      }
    }
  }

  .goods_infor {
    .goods_infor_tit {
      // height: 54px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 14px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 18px;
      h4 {
        width: 64px;
        height: 24px;
        font-size: 16px;
        
        font-weight: 600;
        color: #262626;
        line-height: 24px;
      }
    }
    .table {
      width: 100%;
      margin-bottom: 12px;
      .table_vertical_left {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        padding-left: 22px;
      }
      .order_table_header {
        border: 1px solid #eeeeee;
        border-bottom: none;
        background: rgba(0, 0, 0, 0.02);
        padding: 0 !important;
        font-weight: 600;
        color: #262626;
        .ant-col {
          height: 54px;
          border-right: 1px solid #eeeeee;
          line-height: 54px;
          text-align: center;
        }
      }
      .order_table_box {
        border: 1px solid #eeeeee;
        color: #262626;
        .order_table_list {
          .ant-col {
            height: 100%;
            border-right: 1px solid #eeeeee;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
          }
        }
        .order_table_list_right {
          border-left: "1px solid #f8f8f8";
          text-align: "center";
          align-items: "center";
          .ant-col {
            border-right: 1px solid #eeeeee;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 30px 0 !important;
          }
          &:nth-last-of-type(1) {
            .ant-col {
              border-bottom: none;
            }
          }
        }
      }
    }
    .table_vertical_center_top {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 22px;
      padding: 0 22px;
    }
  }
  .after_sale_progress {
    .after_sale_progress_tit {
      // height: 54px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 14px;
      border-bottom: 1px solid #efefef;
      // margin-top: -18px;
      margin-bottom: 18px;
      h4 {
        width: 64px;
        height: 24px;
        font-size: 16px;
        
        font-weight: 600;
        color: #262626;
        line-height: 24px;
      }
    }
    .sendInfo {
      min-width: 280px;
      max-width: 320px;
      // height: 60px;
      border-radius: 8px;
      background: #fafafa;
      color: #000;
      padding: 8px;
      .second {
        display: flex;
        justify-content: space-between;
      }
      .row {
        color: #262626;
      }
    }
    .sendInfoAddress {
      min-width: 280px;
      max-width: 660px;
      min-height: 60px;
      max-height: 100px;
      border-radius: 8px;
      background: #fafafa;
      color: #000;
      padding: 8px;
      .first {
        display: flex;
        justify-content: space-between;
      }
      .second {
        display: flex;
        justify-content: space-between;
      }
      .thirdly {
        margin-left: 98px;
      }
    }
    .ant-timeline-item-last{
      padding-bottom: 0 !important;
    }
  }
  .ant-card {
    margin-bottom: 20px;
  }
}

