.home-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  
  img {
    width: 400px;
  }
}
