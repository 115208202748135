// 订单样式
// 表格
@table_border_color: #eeeeee;
.ant-card-bordered {
  border: none !important;
}
.after_sale_order {
  color: #262626;
  .table_vertical_center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .table_vertical_left {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
  }
  .table_border {
    border-right: none;
    border-bottom: 1px solid @table_border_color;
  }

  .after_sale_form {
    background: #fafafa;
    padding: 20px 48px;
    .ant-form-item-label {
      width: 96px;
    }
  }

  .order_table_header {
    color: #262626;
    text-align: center;
    padding: 10px 0;
    background: none;
    font-weight: 600;
  }

  .row-con {
    .ant-col {
      height: 96px;
    }
  }

  .order_table_div {
    
    margin-bottom: 13px;
    .order_table_list_header {
      color: #262626;
      padding: 10px 0 10px 10px;
      margin: 0 !important;
      background: #f8f8f8;
      .header_time {
        color: #a9a9a9;
      }
    }

    .contact_me {
      cursor: pointer;
      font-size: 12px;
      margin-left: 10px;
      color: #48a9f7;
    }
    .order_table_box {
      text-align: center;
      border: 1px solid @table_border_color;
      border-top: none;
      .order_table_list {
        text-align: center;
        padding: 28px 0 28px 0;
        margin: 0 !important;
        .table_border;
      }
    }

    // 订单状态
    .order_list_state {
      .table_vertical_center;
      .table_border;
      span {
        font-size: 12px;
        color: #999999;
      }
      .order_state {
        font-size: 13px;
        color: #c91623;
        font-weight: 500;
      }
    }
    // 订单价格
    .order_list_price {
      .table_vertical_center;
      .table_border;
      .final_price {
        font-size: 16px;
        font-weight: 500px;
      }
    }

    // 一件代发：订单来源&来源名称
    .order_once_source {
      font-weight: 500;
      .table_vertical_center;
      .table_border;
    }

    // 列表按钮
    .oder_btn_list {
      .table_vertical_center;
      .table_border;
    }
  }

  .goods_name_ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  // 分页
  .pagin_ation {
    text-align: right;
    margin: 30px 0 !important;
  }
}
