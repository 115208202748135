.finace-dgss-manage {
  .finace-dgss-manage-balance {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    background-color: #F0F2F5;
    margin-bottom: 20px;
    .finace-dgss-manage-balance-key {
      font-size: 14px;
      color: #333;
    }
    .finace-dgss-manage-balance-value {
      font-weight: 600;
      left: 10px;
      color: #000;
      font-size: 20px;
    }
  }
  .ant-pro-table {
    .ant-pro-table-search {
      margin-bottom: 20px;
    }
    .ant-card-body {
      padding: 0;
    }
  }
}
