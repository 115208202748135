

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: initial !important;
  min-height: 100vh;
  padding-left: 84px;

  &-image-wrap {
    margin-right: 97px;
  }
  &-image {
    width: 525px;
    height: auto;
  }
  &-content-wrap {
    width: 328px;
  }
  &-status {
    font-size: 118px;
    font-weight: bold;
    color: #e1e1e1;
    margin-bottom: 26px;
    line-height: 84px;
  }
  &-content {
    font-weight: 800;
    color: black;
    margin-bottom: 30px;
  }
}

