#root {
  width: 100%;
  height: 100%;
}
#root .main-route-wrap {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
}
#root .pro-auth-no-auth-center {
  height: auto !important;
}
#root .ant-layout-sider-children {
  height: calc(100vh - 50px) !important;
}
.fx__basic-wrap {
  width: calc(100% - 64px);
  left: 64px;
}
/* flex 布局样式 */
.flex,
.flex-start,
.fle-start-vertical,
.flex-center,
.flex-end,
.flex-space-between,
.flex-space-around,
.flex-start-column,
.flex-center-column,
.flex-end-column,
.flex-space-between-column,
.flex-space-around-column {
  display: flex;
}
.flex-align-center,
.flex-start,
.flex-center,
.flex-end,
.flex-space-between,
.flex-space-around,
.flex-start-column,
.flex-center-column,
.flex-end-column,
.flex-space-between-column,
.flex-space-around-column {
  align-items: center;
}
.flex-column,
.fle-start-vertical,
.flex-start-column,
.flex-center-column,
.flex-end-column,
.flex-space-between-column,
.flex-space-around-column {
  flex-direction: column;
}
.flex-start,
.flex-start-column {
  justify-content: flex-start;
}
.fle-start-vertical {
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-center,
.flex-center-column {
  justify-content: center;
}
.flex-end,
.flex-end-column {
  justify-content: flex-end;
}
.flex-space-between,
.flex-space-between-column {
  justify-content: space-between;
}
.flex-space-around,
.flex-space-around-column {
  justify-content: space-around;
}
/* 文字样式 */
.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.multi-line-text,
.two-line-text,
.three-line-text,
.four-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.two-line-text {
  -webkit-line-clamp: 2;
}
.three-line-text {
  -webkit-line-clamp: 3;
}
.four-line-text {
  -webkit-line-clamp: 4;
}
