@import "@/style";

.modal_body_tip{
  font-size: 12px;
}
.area_container{
  margin-top: 20px;
  .area_cell{
    line-height: 30px;
  }
}


