.company-info-wrap {
  width: 100%;

  .ant-upload-text {
    padding-top: 8px;
  }

  .ant-form {
    width: 650px;

    .upload-img-extra {
      position: absolute;
      left: 120px;
      top: 0;
      color: #999;
    }
  }

  .ant-form-extra {
    position: absolute;
    left: 120px;
    bottom: 0;
  }

  .logoImg {
    width: 104px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin-bottom: 0;
  }

  .ant-form-item-children .ant-statistic-content {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.65);

    .ant-statistic-content-value-decimal,
    .ant-statistic-content-suffix {
      font-size: 14px;
    }
  }
}
