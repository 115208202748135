.my-accountInfo-wrap {
  .ant-descriptions-view {
    padding-left: 30px;
  }

  .tips {
    padding-bottom: 30px;
  }

  .accountInfo-wrap {
    margin-bottom: 30px;

    .ant-form-item {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .ant-input {
        border: none;
      }

      .ant-form-item-label {
        width: 120px;
        text-align: right;
      }
    }
  }

  .ant-input[disabled] {
    color: #333;
    background-color: #fff;
    cursor: auto;
  }
}

.ant-form-item-children .ant-statistic-content {
  font-size: 14px;
  color: #999;
}

.certification-info-wrap {
  position: relative;
  padding: 30px 50px;
  line-height: 40px;

  .anticon {
    position: absolute;
    left: 0;
  }

  .certification-info-text {
    padding-left: 40px;
  }

  .certification-info-title {
    font-size: 20px;
    font-weight: 600;
  }

  .certification-info-tips {
    padding-left: 10px;
    color: #bbb;
  }

  .btn-wrap {
    margin-top: 20px;
  }

}
