@import "@/style";

.table_action_btn{
  color: @primary-color;
  user-select: none;
  cursor: pointer;
}

.freight_edit_container {
  > .ant-row {
    margin-top: 30px;
  }

  .freight_title {
    height: 58px;
    margin-bottom: 20px;
    display: flex;
    color: #333;
    background-color: #f7f8fa;
    padding-left: 32px;
    line-height: 58px;
    font-size: 20px;
    font-weight: 400;
  }

  .freight_input_container {
    border: @--wrapStyle-solid-border;
    border-radius: 4px;
    padding: 10px;
    width: 98%;

    .global_tip {
      font-size: 12px;
    }

    .global_freight_container {
      padding: 10px 20px;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 1.5;

      .global_freight {
        line-height: 2.5;

        .global_input {
          width: 80px;
          font-size: 12px;
          margin: 0 5px;
        }
      }
    }

    .part_freight_container {
      margin-top: 10px;

      .part_freight_title {
        background-color: #f0f0f0;

        .part_freight_title_text {
          text-align: center;
          line-height: 30px;
        }
      }

      .part_freight_content {
        .content_row {
          border-bottom: @--wrapStyle-solid-border;

          .content_cell {
            border-right: @--wrapStyle-solid-border;
            display: flex;
            justify-content: center;

            align-items: center;
            min-height: 50px;

            .area_container {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 6px;
              line-height: 1.5;

              .area_names {
                flex: 4;
              }
              .area_edit_btn:extend(.table_action_btn) {
                display: block;
                width: 40px;
              }
            }

            .cell_input {
              width: 80px;
              text-align: center;
              font-size: 12px;
            }
          }
          .content_cell:nth-last-child(1) {
            border-right: none;
          }
        }
      }
    }
  }
}

.disabled_area_container {
  padding: 8px 16px 0;
}

