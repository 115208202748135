#root {
  width: 100%;
  height: 100%;
  .main-route-wrap {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #f0f2f5;
  }
  .pro-auth-no-auth-center {
    height: auto !important;
  }

  .ant-layout-sider-children {
    height: calc(100vh - 50px) !important;
  }
}

.fx__basic-wrap {
  width: calc(100% - 64px);
  left: 64px;
}
