@import './vars.less';

/* flex 布局样式 */
.flex {
    display: flex;
}

.flex-align-center {
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.flex-start {
    &:extend(.flex);
    &:extend(.flex-align-center);
    justify-content: flex-start;
}

.fle-start-vertical {
    &:extend(.flex);
    &:extend(.flex-column);
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-start-column {
    &:extend(.flex-start);
    &:extend(.flex-column);
}

.flex-center {
    &:extend(.flex);
    &:extend(.flex-align-center);
    justify-content: center;
}

.flex-center-column {
    &:extend(.flex-center);
    &:extend(.flex-column);
}

.flex-end {
    &:extend(.flex);
    &:extend(.flex-align-center);
    justify-content: flex-end;
}

.flex-end-column {
    &:extend(.flex-end);
    &:extend(.flex-column);
}

.flex-space-between {
    &:extend(.flex);
    &:extend(.flex-align-center);
    justify-content: space-between;
}

.flex-space-between-column {
    &:extend(.flex-space-between);
    &:extend(.flex-column);
}

.flex-space-around {
    &:extend(.flex);
    &:extend(.flex-align-center);
    justify-content: space-around;
}

.flex-space-around-column {
    &:extend(.flex-space-around);
    &:extend(.flex-column);
}


/* 文字样式 */

.one-line-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.multi-line-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.two-line-text {
    &:extend(.multi-line-text);
    -webkit-line-clamp: 2;
}

.three-line-text {
    &:extend(.multi-line-text);
    -webkit-line-clamp: 3;
}

.four-line-text {
    &:extend(.multi-line-text);
    -webkit-line-clamp: 4;
}