.certification-wrap {
  width: 100%;

  .ant-upload-text {
    padding-top: 8px;
  }

  .ant-alert-with-description {
    margin-bottom: 30px;
  }

  .ant-form {
    width: 650px;

    .upload-img-extra {
      position: absolute;
      left: 120px;
      top: 0;
      color: #999;
    }
  }

  .ant-form-extra {
    position: absolute;
    left: 120px;
    bottom: 0;
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin-bottom: 0;
  }

  .type-tips {
    margin-bottom: 40px;
  }

  .businessLicense-img {
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    padding: 8px;
    border-radius: 4px;

    img {
      width: 100%;
    }
  }
}
