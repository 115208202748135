.finace-manager-wrap {
  padding: 20px;
  
  .ant-pro-card-body {
    padding: 20px;
  }

  .ant-pro-card-ghost > .ant-pro-card-body {
    padding: inherit;
  }

  .finace-icon-wrap {
    border-radius: 10px;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 28px;
    color: #4178fe;
    background-color: #e1ebff;
  }

  .finace-card-title {
    color: #333;
    font-size: 14px;
  }

  .finace-card-subtitle {
    color: #787878;
    font-size: 12px;
  }

  .flex-center {
    height: 100%;
  }

  .finace-tab-wrap {
    position: relative;

    .finace-tab-btn {
      position: absolute;
      top: 30px;
      right: 5px;
      z-index: 1;
      color: #4C4C4C;

      &:hover {
        color: #2c68ff;
      }
    }
  }

  .router-btn {
    // color: #4C4C4C;
    cursor: pointer;

    &:hover {
      color: #2c68ff;
    }
  }
}

