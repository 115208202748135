@import "@/style";

.freight_temp_list_container {
  min-height: 500px;

  .add_line_container {
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .add_line_tip {
      margin-left: 32px;
    }
  }

  .temp_list_container {

    .temp_container {
      margin-bottom: 20px;
      border-radius: 2px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

      .temp_head_container {
        line-height: 40px;
        padding: 0 10px 0 20px;
        display: flex;
        font-size: 12px;
        align-items: center;
        justify-content: space-between;
        border-bottom: @--wrapStyle-solid-border;

        .head_info {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          p {
            margin-right: 20px;
          }

          .temp_name {
            color: #000;
          }
        }

        .ant-btn-sm {
          font-size: 12px;
        }

        .temp_from, .temp_used, .temp_time {
          color: #9b9b9b;
          padding-right: 10px;
        }
      }

      .temp_content_container {

        .temp_content_title {
          padding: 0 20px;
          line-height: 40px;
          color: #333;
          font-weight: 500;
          background-color: @grey-bg-op1;
        }

        .temp_content_info {
          padding: 10px 20px;
          line-height: 32px;
          font-size: 13px;
          color: #333;
          border-top: @--wrapStyle-solid-border;
        }
      }
    }
  }

  .fx-flexbox{
    width: auto;
    display: flex;
    align-items: center
  }
}

