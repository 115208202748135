.ant-layout-sider-children {
  background-color: #141A3C !important;
  height: 100vh !important;
}

.fontsize20 {
  font-size: 20px !important;
}

.fx__base-wrap {
  height: 100%;
  background-color: #EFF1F6;

  &>div {
    height: 100%;
  }

  p {
    margin-bottom: 0;
  }
}
